.titlen {
  position: relative;
  height: 250px;
  background-image: url("/image/2151003782.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  color: wheat;
  text-align: center;
  font-size: 150px;
  
  h1 {
    
    font-size: 60px;
    padding-top: 110px;

  }
}

.doctor-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.doctor-info {
  display: flex;
  align-items: center;
}

.doctor-photo {
  margin-right: 20px;
}

.doctor-details {
  font-size: 25px;
}

.circular-photo {
  width: 250px;
  height: 250px;
  border-radius: 50%; /* To make it circular */
}

.doctor-video {
  margin-top: 20px;
  display: flex;
  justify-content: center; /* Center videos horizontally */
  flex-wrap: wrap; /* Wrap videos to next line if needed */
}

.video-placeholder {
  margin: 10px;
  width: 470px;
  height: 300px; /* Adjust height as needed */
  background-color: #333; /* Dark background color */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Hide overflow content */
  position: relative; /* For positioning overlay */
  cursor: pointer;
  transition: transform 0.3s ease; /* Add transition effect on hover */
}

.video-placeholder:hover {
  transform: scale(1.05); /* Increase size on hover */
}

.video-placeholder::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 1; /* Ensure overlay is above video thumbnail */
}

.video-placeholder:hover::after {
  background: rgba(0, 0, 0, 0.7); /* Darker overlay on hover */
}

.video-placeholder iframe {
  width: 100%;
  height: 100%;
  border: none; /* Remove border from iframe */
}
.docs2 {
  display: flex;
  margin-left: 50px;
  height: 100px;
  margin-top: 55px;
  .doctor {
      
      background-position: center;
      background-size: cover;
      margin-left: 10px;
      width: 300px;
      height: 410px;
      background-color: #d6d4d3;
      transition: all 0.8s ease;
  
      &:hover {
          margin-top: 25px;

          h2 {
              background-color: #f2e9e4;
              color: black;
              transform: translateY(-10px);
              box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          }
      }
          img{
              height: 200px;
          }
      h2 {
          margin-top: 100px;
          color: #003459;
          background-color: transparent;
          padding: 20px;
          transition: color 0.3s, transform 0.3s;
          cursor: pointer;
      }
      animation-delay: 1s;
  }

  
}
.videos-section{
  margin-top: 375px;
  .videos{
    margin-top: 50px;
    margin-left: 20px;
  }
  h2{
    text-align: center;
    font-size: 29px;
    color: #003459;

    span{
      color: black;
      font-size: 34px;
      text-decoration: underline;
    }
  }
}