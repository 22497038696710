.navbar {
  width: 100%;
  color: rgb(235, 229, 229);
  font-size: 14px;
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: #2f2228;
  transition: background-color 0.3s ease;
  
  &.scrolled {
    background-color:#2f2228;
    color: #ebf2fa;
  }

  .container {
    padding: 0px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;

    .topLeft {
      
      display: flex;
      align-items: center;

      img {
        max-width:  60px;
        margin-right: 20px;
        transition: transform 0.3s ease;
      }
    }

    .center {
      flex-grow: 1;
      display: flex;
      justify-content: center;

      .links {
        display: flex;
        align-items: center;

        span {
          margin-right: 20px;
          cursor: pointer;
          transition: color 0.3s ease; /* Added transition for text color change */
          position: relative;

          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: -3px;
            left: 0;
            background-color: transparent;
            transition: background-color 0.3s ease;
          }

          &:hover {
            color: #d6e6e6; /* Change text color on hover */
            &:after {
              background-color: #092327; /* Change underline color on hover */
            }
            transform: scale(1.1); /* Scale up on hover */
            opacity: 0.8; /* Reduce opacity on hover */
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .notification-icon,
      .account-icon {
        font-size: 30px;
        cursor: pointer;
        margin-left: 20px;
        transition: transform 0.3s ease;
      }
      .language-dropdown {
        margin-right: 10px;
      
        select {
          padding: 5px;
          border-radius: 5px;
          border: 1px solid #ccc;
          background-color: #fff;
          font-size: 14px;
          cursor: pointer;
          transition: border-color 0.3s ease;
      
          &:focus {
            outline: none;
            border-color: #00a9a5;
          }
        }
      }
    }
  }
}

/* Animation for scrolling */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.navbar.scrolled .container {
  animation: fadeInDown 0.5s ease;
}