.Section5 {
    display: flex;
    margin-top: 70px;
    background-image: url("/image/2151252468.jpg");
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    height: 500px;
    width: 100%;

    h3 {
        margin-top: 180px;
        margin-left: 160px;
        color: white;
        font-size: 80px;

        span {
            color: white; // Start with white color
            animation: colorTransition 2s infinite alternate; // Animation for color transition
            opacity: 0; // Start with opacity 0
            animation-delay: 10s; // Delay for fading in
            animation-fill-mode: forwards; // Keep the last keyframe state
        }
    }

    p {
        color: aliceblue;
        margin-left: 140px;
        margin-bottom: 200px;
        width: 550px;
        opacity: 0; // Start with opacity 0
        animation: fadeInRight 2s forwards;
        animation-delay: 1s; // Delay for the fade-in animation
    }

    .lottie {
        position: relative;
        margin-bottom: 50px;
        width: 400px;
    }

    @keyframes colorTransition {
        0% {
            color: white;
            opacity: 0;
        }
        50% {
            color: #2f2228;
            opacity: 1; // Fade in halfway through the animation
        }
        100% {
            color: white;
            opacity: 0;
        }
    }
    @keyframes fadeInRight {
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
}
.middle {
    position: absolute;
    display: flex;
    height: 630px;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

.form {
    height: 600px;
    margin-top: 20px;
    margin-left: 100px;
    color: rgb(0, 0, 0);
    position: absolute;
    padding-top: 50px;
    padding-left: 75px;
    width: 550px;
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
    animation: slideInLeft 1s forwards; /* Apply slide in animation */
}

input[type=text],
input[type=email] {
    height: 30px;
    width: 80%;
    border: 1px solid gray;
    border-radius: 10px;
}

.btn1 {
    margin-top: 30px;
    position: absolute;
    font-family: 'Times New Roman', Times, serif;
    color: white;
    font-size: 25px;
    width: 180px;
    height: 50px;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.3s;
    margin-left: 100px;
}

.form button:hover {
    background-color: #2f2228;
}

.con-p {
    .lottie {
        margin-top: 130px;
        margin-left: 800px;
        width: 500px;
        animation: slideInRight 1s forwards; /* Apply slide in animation */
    }
}

@keyframes slideInLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}