body{
    background-color: #ccac94;
}
.section2{
    display: flex;
    margin-top: 70px;
    background-image: url("/image/2151003770.jpg");
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    height: 500px;
    width: 100%;

    h3 {
        margin-top: 180px;
        margin-left: 160px;
        color: white;
        font-size: 50px;

        span {
            color: white; // Start with white color
            animation: colorTransition 2s infinite alternate; // Animation for color transition
            opacity: 0; // Start with opacity 0
            animation-delay: 10s; // Delay for fading in
            animation-fill-mode: forwards; // Keep the last keyframe state
        }
    }

    p {
        color: aliceblue;
        margin-left: 140px;
        margin-bottom: 200px;
        width: 550px;
        opacity: 0; // Start with opacity 0
        animation: fadeInRight 2s forwards;
        animation-delay: 1s; // Delay for the fade-in animation
    }

    .lottie {
        position: relative;
        margin-bottom: 70px;
        width: 550px;
    }

    @keyframes colorTransition {
        0% {
            color: white;
            opacity: 0;
        }
        50% {
            color: #E59c71;
            opacity: 1; // Fade in halfway through the animation
        }
        100% {
            color: white;
            opacity: 0;
        }
    }
    @keyframes fadeInRight {
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
}
.mid-section {
    margin-top: 50px;
    display: flex;
    height: 500px;
    position: relative;

    .lottie {
        margin-top: 50px;
        margin-left: 100px;
        width: 500px;
        height: 500px;
        opacity: 0; // Start with opacity 0
        animation: fadeInBottom 2s forwards;
        animation-delay: 1s; // Delay for the fade-in animation
    }

    .p {
        margin-top: 50px;
        margin-left: 140px;
        width: 550px;

        h3 {
            font-size: 50px;
            color: #2f2228;
            text-decoration: underline;
            opacity: 0; // Start with opacity 0
            animation: fadeInRightH3 1.5s forwards; // Different duration for h3 fade-in animation
            animation-delay: 0.5s; // Delay for the fade-in animation
        }

        p {
            color: black;
            font-size: 17px;
            margin-bottom: 50px;
            opacity: 0; // Start with opacity 0
            animation: fadeInRightP 1.5s forwards; // Different duration for p fade-in animation
            animation-delay: 1s; // Delay for the fade-in animation
        }
    }

    button {
        position: relative;
        opacity: 0; // Start with opacity 0
        animation: fadeInButton 1.5s forwards; // Animation for button fade-in
        animation-delay: 1s;
        //display: flex;
        //justify-content: center; // Center the button horizontally
        //align-items: center;
    }

     button {
        align-items: center;
        color: #ccac94;
        font-size: 18px;
        width: 180px;
        height: 40px;
        border-radius: 5px;
        background-color: #2f2228;
        transition: all 0.3s;
    }

    a button:hover {
        background-color: #000000;
    }

    

    @keyframes fadeInBottom {
        from {
            opacity: 0;
            transform: translateY(100%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes fadeInRightH3 {
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes fadeInRightP {
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
    @keyframes fadeInButton {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
.sec_mid{
    .title{
        margin-top: 30px;
        position: relative;
        height: 250px;
        background-image: url("/image/2151133554.jpg");
       background-attachment: fixed;
       background-position: center;
       background-size: cover;
        color: wheat;
        text-align: center;
        font-size: 150px;
        h2{
            font-size: 60px;
            padding-top: 90px;
        }
    }
    .experts {
        height: 300px;
        margin-top: 50px;
        display: flex;
    }
    .mental, .physical, .spirit {
        margin-left: 50px;
        position: relative;
        width: 400px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center; /* Center vertically */
        align-items: center; /* Center horizontally */
        background-color: white; /* Set background color to white */
        border-radius: 20px; /* Set border radius to 20px */
        border: 2px solid #2f2228; 
        transition: all 0.8s ease; /* Add transition for smooth hover effect */
        color: #2f2228;
    }
    
    .mental:hover, .physical:hover, .spirit:hover {
        color: wheat;
        background-color: #2f2228;
        transform: translateY(20px); /* Move down on hover */
    }
    
    .mental {
    }
    
    .physical {
        margin-left: 10px;
    }
    
    .spirit {
        margin-left: 10px;
    }
    
    .lottie {
        width: 250px;
    }
    
    h2 {
        font-family: 'Times New Roman', Times, serif;
        font-size: 25px;
        text-align: center;
        margin: 0; /* Remove default margin */
    }
    
    /* Animation */
    @keyframes fadeDown {
        from {
            opacity: 0;
            transform: translateY(-250px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
    
    .mental, .physical, .spirit {
        animation: fadeDown 2s e; /* Apply animation */
    }
    
    .physical {
        animation-delay: 0.5s; /* Delay animation */
    }
    
    .spirit {
        animation-delay: 1s; /* Delay animation */
    }
}
.bon1{
    a  {
        margin-top: 50px;
        position: relative;
        opacity: 0; // Start with opacity 0
        animation: fadeInButton 1.5s forwards; // Animation for button fade-in
        animation-delay: 1s;
        display: flex;
        justify-content: center; // Center the button horizontally
        align-items: center;
    }
    
    a button {
        align-items: center;
        color: #ccac94;
        font-size: 18px;
        width: 180px;
        height: 40px;
        border-radius: 5px;
        background-color: #2f2228;
        transition: all 0.3s;
    }
    
    a button:hover {
        color: #000000;
        background-color: #ffffff;
    }
    }
    .docvids {
        margin-top: 30px;
        margin-left: 10px;
        height: 300px;
        display: flex;
        position: relative;
    }
    
    .v1, .v2, .v3, .v4 {
        position: relative;
        margin-left: 30px;
        opacity: 0;
        animation-fill-mode: forwards;
    }
    
    .v1 {
        animation: fadeInUp 1.5s ease 0.5s forwards;
    }
    
    .v2 {
        animation: fadeInUp 1.5s ease 1s forwards;
    }
    
    .v3 {
        animation: fadeInUp 1.5s ease 1.5s forwards;
    }
    
    .v4 {
        animation: fadeInUp 1.5s ease 2s forwards;
    }
    
    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(30px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
    .content{
       
        margin-top: 200px;
        background-color: #000000;
    }
    .up {
        margin-left: 100px;
        margin-top: 250px;
        display: flex;
      }
      
      .up1, .up2, .up3, .up4 {
        min-width: 300px; /* Adjust as needed */
        min-height: 500px; /* Adjust as needed */
        margin-left: 50px; /* Adjust spacing between text */
        display: flex; /* Ensure content within containers aligns properly */
        align-items: center; /* Center content vertically */
        justify-content: center; /* Center content horizontally */
        background-size: cover; /* Ensure background images cover the container */
        background-position: center; /* Center the background images */
        border: 1px solid #ccc; /* Border for better visibility */
      }
      
      .up1 {
        background-position: center;
        background-size: cover;
        background-image: url('/image/2150771075.jpg'); /* Specify background image URL */
      }
      
      .up2 {
        background-position: center;
        background-size: cover;
        background-image: url('/image/2151064826.jpg'); /* Specify background image URL */
      }
      
      .up3 {
        background-position: center;
        background-size: cover;
        background-image: url('/image/2151202448.jpg'); /* Specify background image URL */
      }
      
      
      
      h2 {
        color: white;
        font-size: 40px;
        margin-top: 500px; /* Remove default margin */
      }
      .btn2{
        a  {
            top: 20px;
            position: relative;
            opacity: 0; // Start with opacity 0
            animation: fadeInButton 1.5s forwards; // Animation for button fade-in
            animation-delay: 1s;
            display: flex;
            justify-content: center; // Center the button horizontally
            align-items: center;
        }
        
         button {
            align-items: center;
            color: #ccac94;
            font-size: 18px;
            width: 180px;
            height: 40px;
            border-radius: 5px;
            background-color: #2f2228;
            transition: all 0.3s;
        }
        
        a button:hover {
            color: #000000;
        background-color: #ffffff;
        }
    }
    .up {
        display: flex;
        margin-top: 350px;
        margin-left: 50px;
        height: 600px;
    
        .doctor {
            background-position: center;
            background-size: cover;
            margin-left: 20px;
            width: 300px;
            height: 380px;
            transition: all 0.8s ease;
            background-color: #d6d4d3;
            &:hover {
                margin-top: 25px;
    
                h2 {
                    background-color: #f2e9e4;
                    color: black;
                    transform: translateY(-10px);
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                }
            }
                img{
                    height: 200px;
                }
            h2 {
                margin-top: 0px;
                color: #cf6b44;
                background-color: transparent;
                padding: 20px;
                transition: color 0.3s, transform 0.3s;
                cursor: pointer;
            }
            animation-delay: 1s;
        }
    
        
    }