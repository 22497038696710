.cat {
    .sectionca {
        display: flex;
        margin-top: 70px;
        background-image: url("/image/2150946795.jpg");
        background-position: center;
        background-attachment: fixed;
        background-size: cover;
        height: 500px;
        width: 100%;
        filter: brightness(0.8);

        h3 {
            margin-top: 180px;
            margin-left: 160px;
            color: #ccac94;
            font-size: 90px;

            span {
                color: white;
                animation: colorTransition 2s infinite alternate;
                opacity: 0;
                animation-delay: 10s;
                animation-fill-mode: forwards;
            }
        }

        p {
            color: aliceblue;
            margin-left: 140px;
            margin-bottom: 200px;
            width: 550px;
            opacity: 0;
            animation: fadeInRight 2s forwards;
            animation-delay: 1s;
        }

        .lottie {
            left: 50px;
            position: relative;
            margin-bottom: 70px;
            width: 650px;
            filter: brightness(1);
        }

        @keyframes colorTransition {
            0% {
                color: white;
                opacity: 0;
            }
            50% {
                color: #2f2228;
                opacity: 1;
            }
            100% {
                color: white;
                opacity: 0;
            }
        }
        @keyframes fadeInRight {
            from {
                opacity: 0;
                transform: translateX(100%);
            }
            to {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
}

.catagory {
    position: relative;
    margin-top: 30px;
    height: 2200px;
    .tech{
    padding-left: 100px;
    display: flex;
        h3{
            text-decoration: underline;
            margin-top: 150px;
            margin-left: 100px;
            text-align: center;
            font-size: 50px;
            color: #2f2228;
        }
        .lottie{
            padding-left: 100px;
            width: 500px;
       }
       .techv{
        margin-top: 400px;
        display: flex;
        position: absolute;

       }
       .video-placeholder1 {
        margin-right: 20px;
        margin-left: 10px;
        width: 270px;
        height: 200px;
        background-color: black;
        cursor: pointer;
    }
    }
    .cod{
        margin-top: 560px;
        padding-left: 100px;
        display: flex;
            h3{
                text-decoration: underline;
                margin-top: 250px;
                margin-left: 100px;
                text-align: center;
                font-size: 50px;
                color: #2f2228;
            }
            .lottie{
                padding-left: 100px;
                width: 500px;
           }
           .codv{
            margin-top: 430px;
            display: flex;
            position: absolute;
    
           }
           .video-placeholder1 {
            margin-top: 60px;
            margin-right: 20px;
            margin-left: 10px;
            width: 270px;
            height: 200px;
            background-color: black;
            cursor: pointer;
        }
        }
        .gra{
            margin-top: 470px;
            padding-left: 100px;
            display: flex;
                h3{
                    text-decoration: underline;
                    margin-top: 250px;
                    margin-left: 100px;
                    text-align: center;
                    font-size: 50px;
                    color: #2f2228;
                }
                .lottie{
                    padding-left: 100px;
                    width: 500px;
               }
               .grav{
                margin-top: 480px;
                display: flex;
                position: absolute;
        
               }
               .video-placeholder1 {
                margin-top: 60px;
                margin-right: 10px;
                margin-left: 10px;
                width: 270px;
                height: 200px;
                background-color: black;
                cursor: pointer;
            }
            }
    .cog {
        margin-top: 80px;
        display: flex;
        position: absolute;

        .video-placeholder {
            margin-right: 10px;
            margin-left: 10px;
            width: 270px;
            height: 200px;
            background-color: black;
            cursor: pointer;
        }

        button {
            margin-top: 130px;
            position: relative;
            opacity: 0;
            animation: fadeInButton 1.5s forwards;
            animation-delay: 1s;
            color: white;
            font-size: 18px;
            width: 180px;
            height: 60px;
            border: none;
            border-radius: 30px;
            background-color: #003459;
            transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
            cursor: pointer;
            overflow: hidden;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        button:hover {
            background-color: #000000;
            box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
        }

        button span {
            position: relative;
            z-index: 1;
        }

        button::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 300%;
            height: 300%;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            transition: transform 0.6s ease-out;
            transform: translate(-50%, -50%) scale(0);
        }

        button:hover::before {
            transform: translate(-50%, -50%) scale(1);
        }

        @keyframes fadeInButton {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }

    .phy {
        margin-top: 600px;
        display: flex;
        position: absolute;
        h2 {
            position: absolute;
            margin-left: 25px;
            font-size: 50px;
            color: #003459;
        }
        .video-placeholder {
            margin-top: 70px;
            margin-right: 10px;
            margin-left: 10px;
            width: 270px;
            height: 200px;
            background-color: black;
            cursor: pointer;
        }

        button {
            margin-top: 200px;
            position: relative;
            opacity: 0;
            animation: fadeInButton 1.5s forwards;
            animation-delay: 1s;
            color: white;
            font-size: 18px;
            width: 180px;
            height: 60px;
            border: none;
            border-radius: 30px;
            background-color: #003459;
            transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
            cursor: pointer;
            overflow: hidden;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        button:hover {
            background-color: #000000;
            box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
        }

        button span {
            position: relative;
            z-index: 1;
        }

        button::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 300%;
            height: 300%;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            transition: transform 0.6s ease-out;
            transform: translate(-50%, -50%) scale(0);
        }

        button:hover::before {
            transform: translate(-50%, -50%) scale(1);
        }

        @keyframes fadeInButton {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }

    .sir {
        margin-top: 1200px;
        display: flex;
        position: absolute;
        h2 {
            position: absolute;
            margin-left: 25px;
            font-size: 50px;
            color: #003459;
        }
        .video-placeholder {
            margin-top: 70px;
            margin-right: 10px;
            margin-left: 10px;
            width: 270px;
            height: 200px;
            background-color: black;
            cursor: pointer;
        }

        button {
            margin-top: 200px;
            position: relative;
            opacity: 0;
            animation: fadeInButton 1.5s forwards;
            animation-delay: 1s;
            color: white;
            font-size: 18px;
            width: 180px;
            height: 60px;
            border: none;
            border-radius: 30px;
            background-color: #003459;
            transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
            cursor: pointer;
            overflow: hidden;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        button:hover {
            background-color: #000000;
            box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
        }

        button span {
            position: relative;
            z-index: 1;
        }

        button::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 300%;
            height: 300%;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            transition: transform 0.6s ease-out;
            transform: translate(-50%, -50%) scale(0);
        }

        button:hover::before {
            transform: translate(-50%, -50%) scale(1);
        }

        @keyframes fadeInButton {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }
}
