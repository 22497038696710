
.section1 {
    display: flex;
    margin-top: 70px;
    background-image: url("/image/4827.jpg");
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    height: 500px;
    width: 100%;
    filter: brightness(0.8);

    h3 {
        margin-top: 180px;
        margin-left: 160px;
        color: white;
        font-size: 90px;

        span {
            color: white; // Start with white color
            animation: colorTransition 2s infinite alternate; // Animation for color transition
            opacity: 0; // Start with opacity 0
            animation-delay: 10s; // Delay for fading in
            animation-fill-mode: forwards; // Keep the last keyframe state
        }
    }

    p {
        color: aliceblue;
        margin-left: 140px;
        margin-bottom: 200px;
        width: 550px;
        opacity: 0; // Start with opacity 0
        animation: fadeInRight 2s forwards;
        animation-delay: 1s; // Delay for the fade-in animation
    }

    .lottie {
        position: relative;
        margin-bottom: 70px;
        width: 650px;
        filter: brightness(1);
    }

    @keyframes colorTransition {
        0% {
            color: white;
            opacity: 0;
        }
        50% {
            color: #ccac94;
            opacity: 1; // Fade in halfway through the animation
        }
        100% {
            color: white;
            opacity: 0;
        }
    }
    @keyframes fadeInRight {
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
}
.tit{
    text-align: center;
    font-size: 45px;
    padding-top: 20px;
    color: #2f2228;
    text-decoration: underline;
}
.detail {
    
    background-attachment: fixed;
    border-radius: 20%;
    background-image: url("/image/2150904639.jpg");
    margin-top: 20px;
    height: 500px;
    background-color: black;
    color: black;
    h3{
        font-size: 50px;
        padding-top: 100px;
        margin-left: 725px;
       color: rgb(250, 250, 250);
       width: 525px;
    }
    p{
        padding-top: 20px;
         margin-left: 725px;
        color: rgb(250, 250, 250);
        width: 525px;
    }
}
.detail2 {
    height: 500px;
}

.de1 {
    margin-left: 150px;
    background-size: cover;
    margin-top: 100px;
    display: flex;
    color: rgb(0, 0, 0);
    position: relative;

    .lottie {
        backdrop-filter: blur(10px);
        width: 300px;
        margin-left: 100px;
        animation: fadeInRightLottie 2s forwards; /* Apply animation to .lottie */
    }

    p {
        width: 600px;
        margin-top: 90px;
        margin-left: 100px;
        animation: fadeInRightP 2s forwards; /* Apply animation to p */
    }
}

.de2 {
    background-size: cover;
    margin-top: 20px;
    display: flex;
    color: rgb(0, 0, 0);
    height: 400px;
    position: relative;

    .lottie {
        margin-bottom: 200px;
        width: 400px;
        margin-left: 50px;
        animation: fadeInLeftLottie 2s forwards; /* Apply animation to .lottie */
    }

    p {
        width: 600px;
        margin-top: 90px;
        margin-left: 100px;
        animation: fadeInLeftP 2s forwards; /* Apply animation to p */
    }
}

.de3 {
    position: relative;
    bottom: 230px;
    display: flex;
    color: rgb(0, 0, 0);
    height: 400px;
    .lottie {
        width: 300px;
        margin-left: 200px;
        animation: fadeInRightLottie 2s forwards; /* Apply animation to .lottie */
    }

    p {
        width: 600px;
        margin-top: 90px;
        margin-left: 200px;
        animation: fadeInRightP 2s forwards; /* Apply animation to p */
    }
}

@keyframes fadeInRightLottie {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRightP {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInLeftLottie {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInLeftP {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

